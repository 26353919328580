const QUERIES = {
  USERS_LIST: 'user-list',
  USERS_REGISTER_LIST: 'users-register-list',
  USERS_BY_ID: 'user-by-id',
  OPTION_LIST: 'option-list',
  UNIT_BY_id: 'get-unit-by-id',
  LIST_ROLE: 'list-role',
  PRODUCT_LIST: 'product-list',
  CUSTOMER_LIST: 'customer-list',
  CUSTOMER_BY_ID: 'customer-by-id',
  CUSTOMER_LIST_ADDRESS: 'customer-get-all-address',
  BOOKING_LIST: 'booking-list',
  BOOKING_BY_ID: 'booking-by-id',
  ORDER_LIST: 'order-list',
  ORDER_BY_ID: 'order-by-id',
}

export {QUERIES}
